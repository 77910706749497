const IntroText = () => {
    return (
        <div>
            <h2>Welcome to </h2>
            <h1>THE GAME</h1>
            <br/>
            <br/>
            <br/>
            <br/>
            By Bound Theatre
        </div>
    )
}

export default IntroText;